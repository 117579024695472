<template>
    <div class="login-page">
        <div class="page-left">
            <img class="img-icon" src="@/assets/new-logo.jpg" />
        </div>
        <div class="page-right">
            <div @click="toggleDownload" class="down-load-btn">
                <img
                    src="@/assets/downloadBlack.png"
                    alt="download"
                    class="down-load-img"
                />
                <span class="down-text">下载领聚云APP，享更多掌上便捷</span>
                <div v-show="downloadShow" class="code-show">
                    <img src="@/assets/code.png" alt="code" />
                </div>
            </div>
            <div class="login-content-box">
                <div class="top-title">
                    Mysitefy用户平台登录
                </div>
                <div class="login-content">
                    <!-- 登录 -->
                        <div class="setCodeBox">
                            <div class="setPassText">忘记密码</div>
                            <div>
                                <el-form ref="form" :model="form" :rules="rules">
                                    <el-form-item prop="mobile">
                                        <el-input
                                              v-model="form.mobile"
                                              ref="mobile"
                                              maxlength="11"
                                              type="text"
                                              placeholder="请输入手机号" >
                                          <template slot="prepend">+86</template>
                                          </el-input>
                                    </el-form-item>
                                    <el-form-item prop="password">
                                        <div class="pass-inp">
                                            <div class="pass-wd">
                                                <p>新密码</p>
                                                <el-input :type="passwordType?'text':'password'" placeholder="密码长度至少8位，必须符合由数字,大写字母,小写字母,特殊符,至少其中三种组成密码" v-model="form.password"></el-input>
                                            </div>
                                                <template v-if="form.password.length">
                                                    <img v-show="!passwordType" src="../../assets/passWord.png" alt="" @click="passwordLook('password')">
                                                    <img v-show="passwordType" src="../../assets/hidden-img.png" alt="" @click="passwordLook('password')">
                                                </template>
                                        </div>
                                    </el-form-item>
                                    <el-form-item prop="second_confirmation">
                                        <div class="pass-inp">
                                            <div class="pass-wd">
                                            <p>确认密码</p>
                                            <el-input :type="confirmationType?'text':'password'" placeholder="密码长度至少8位，必须符合由数字,大写字母,小写字母,特殊符,至少其中三种组成密码" v-model="form.second_confirmation"></el-input>
                                            </div>
                                            <template v-if="form.second_confirmation.length">
                                                    <img v-show="!confirmationType" src="../../assets/passWord.png" alt="" @click="passwordLook('second_confirmation')">
                                                    <img v-show="confirmationType" src="../../assets/hidden-img.png" alt="" @click="passwordLook('second_confirmation')">
                                            </template>
                                        </div>
                                    </el-form-item>
                                    <el-form-item prop="code">
                                        <div class="pass-inp getCode">
                                            <el-input placeholder="请输入验证码" v-model="form.code"></el-input>
                                            <div class="get-code" :class="{'codeDisable': timer!==null}" @click="getCode">{{codeText}}</div>
                                        </div>
                                    </el-form-item>
                                    <el-form-item>
                                        <el-button class="confirmBtn" @click="confirmPassWord">确定</el-button>
                                    </el-form-item>
                                </el-form>
                                <div class="goBack" @click="goBack">
                                    <span>返回登录</span>
                                    <i class="el-icon-arrow-right"></i>
                                </div>
                            </div>
                        </div>
                </div>
            </div>
            <!-- 未注册 -->
            <el-dialog
                :visible.sync="noRegister"
                width="25%">
                <div class="noRegister">
                    <span class="no-register-text">该账号尚未注册</span>
                    <span slot="footer" class="dialog-footer">
                        <el-button @click="again">重新输入</el-button>
                        <el-button type="primary" class="registered" @click="register">现在注册</el-button>
                    </span>
                </div>
            </el-dialog>

            <div class="copyright">
                <div class="copy">
                    Copyright © 2016-2022 Mysitefy All rights reserved
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ajax from "../../js/ajax.js";
import global from "../../../global.js";
export default {
    components: {},
    data() {
        const validatePass2 = (rule, value, callback) => {
            if (value === "") {
                callback(new Error("请再次输入密码"));
            } else if (value !== this.form.password) {
                callback(new Error("两次输入密码不一致!"));
            } else {
                callback();
            }
        };
        return {
            form: {
                mobile: '',
                code: '',
                password: '',
                second_confirmation: ''
            },
            qrCodeTips: "请使用“领聚云APP”扫描上方二维码登陆",
            downloadShow: false, // 是否显示下载二维码
            rules:{
                mobile: [
                    { required: true, message: "请输入手机号码", trigger: "blur" },
                    { min: 11, max:11, message: "请输入11位手机号码", trigger: "blur" },
                    { pattern: /^[1-9]+[0-9]*$/, message: "请输入纯数字的手机号码", trigger: "blur" }
                ],
                code:[
                    { required: true, message: "请输入验证码", trigger: "blur" },
                    { min: 6, max:6, message: "请输入6位数的验证码", trigger: "blur" },
                    { pattern: /^[1-9]+[0-9]*$/, message: "请输入纯数字的验证码", trigger: "blur" }
                ],
                password: [
                    { required: true, pattern: /^(?![a-zA-Z]+$)(?![A-Z0-9]+$)(?![A-Z\\W_!@#$%^&*`~()-+=]+$)(?![a-z0-9]+$)(?![a-z\\W_!@#$%^&*`~()-+=]+$)(?![0-9\\W_!@#$%^&*`~()-+=]+$)[a-zA-Z0-9\\W_!@#$%^&*`~()-+=]{8,30}$/, message: "登录密码长度至少8位，必须符合由数字,大写字母,小写字母,特殊符,至少其中三种组成密码", trigger: "blur" }
                ],
                second_confirmation: [
                    { required: true, validator: validatePass2, message: "两次输入的密码不一致", trigger: "blur" }
                ]
            },
            noRegister: false,
            codeText: '获取验证码',
            Countdown: 60, // 默认倒计时60s
            timer: null, // 全局定时器
            passwordType: false, // 密码查看
            confirmationType: false
        };
    },
    computed: {
        loginUrl() {
             switch (global.env) {
                case "dev": // dev
                    return "http://10.10.20.228:3030/register";
                case "test": // test
                    return "http://testregister.linkjoint.cn/register";
                case "pro": // pro
                    return "https://accounts.mysitefy.com/register";
                default:
                    return "http://192.168.1.196:3030/register"; // 本机环境默认开发
            }
        }
    },
    async created() {
    },

    mounted() {},
    methods: {
        // 密码查看
        passwordLook(value){
            if (value==='password') {
                this.passwordType = !this.passwordType
            } else {
                this.confirmationType = !this.confirmationType
            } 
        },
        toggleDownload() {
            this.downloadShow = !this.downloadShow;
        },
        confirmPassWord() {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    this.setPassWd()
                } else {
                    return false;
                }
            });
        },
        async setPassWd (){
            const password  = this.$md5(this.form.password)
            const second_confirmation = this.$md5(this.form.second_confirmation)
            const { mobile, code } = this.form 
            const { data } = await ajax.post("/api/user/resetPassword", { password, second_confirmation, mobile, code });
            if (data.code === 200) {
                this.$message.success('重置密码成功')
                this.$router.push('/')
            }
            if (data.code === 30004) {
               this.noRegister = true 
            }
        },
         // 获取验证码
        async getCode() {
            if (this.timer !== null) {
                return
            }
            const { mobile } = this.form
            const { data } = await ajax.post("/api/user/getResetCode", { mobile });
            if (data.code === 200) {
                this.handelCountdown()
            }
            if (data.code === 30004) {
               this.noRegister = true 
            }
        },
        handelCountdown() {
            /** *
             * 倒计时
             * */
            this.codeText = `${this.Countdown}s后重新获取`;
            this.timer = setInterval(() => {
                this.Countdown --;
                this.codeText = this.Countdown<10?`0${this.Countdown}s后重新获取`:`${this.Countdown}s后重新获取`;
                if (this.Countdown === 0) {
                    this.codeText = "获取验证码";
                    clearInterval(this.timer);
                    this.Countdown = 60;
                    this.timer = null
                }
            }, 1000);
        },
         // 注册
        register() {
            window.location.href = this.loginUrl
        },
        // 重新输入
        again(){
            this.noRegister = false
            this.$refs.mobile.focus()
        },
        goBack(){
            this.$router.push('/')
        }
    }
};
</script>

<style lang="scss" scoped>

.login-page {
    width: 100vw;
    height: 100%;
    display: flex;

    .page-left {
        width: 53vw;
        box-sizing: border-box;
        background: url("../../assets/bg.png");
        background-repeat: repeat;
        background-size: contain;
        display: flex;
        justify-content: center;
        align-items: center;
        .img-icon {
            width: 153px;
            height: 53px;
        }
    }

    .page-right {
        width: 47vw;
        height: 100vh;
        box-sizing: border-box;
        position: relative;
        .down-load-btn {
            position: absolute;
            cursor: pointer;
            top: 24px;
            right: 24px;
            width: 298px;
            height: 44px;
            border-radius: 2px;
            border: 1px solid #d9d9d9;
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 1;
            .down-text {
                font-size: 16px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: rgba(0, 0, 0, 0.65);
                line-height: 22px;
            }
            .down-load-img {
                width: 24px;
                height: 24px;
            }
            .code-show {
                position: absolute;
                width: 298px;
                height: 298px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: 343px;
                background: #fff;
                box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
                &::before {
                    content: "";
                    position: absolute;
                    border-top: 20px solid transparent;
                    border-left: 20px solid transparent;
                    border-right: 20px solid transparent;
                    border-bottom: 20px solid #fff;
                    width: 0;
                    height: 0;
                    margin-top: -313px;
                    margin-left: -32px;
                }
                img {
                    width: 200px;
                }
            }
        }
        .login-content-box {
            width: 406px;
            padding-bottom: 50px;
            background: #ffffff;
            box-shadow: 0px 4px 20px 4px rgba(74, 130, 244, 0.09);
            border-radius: 20px;
            // border: 1px solid #d8e5fe;
            position: absolute;
            top: 43%;
            left: 50%;
            transform: translate(-50%, -40%);
            position: relative;
            .top-title {
                width: 211px;
                height: 48px;
                background: url("../../assets/top-title-bg.png") no-repeat top
                    center / 100% 100%;
                margin: 0 auto;
                position: absolute;
                left: 25%;
                margin-top: -25px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #ffffff;
                line-height: 25px;
            }

            .login-content {
                .setCodeBox{
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    background-color: #fff;
                    min-width: 405px;
                    padding-top: 31px;
                    box-sizing: border-box;
                    border-radius: 15px;
                    margin:0 auto;
                    .setPassText{
                        margin-bottom: 32px;
                        margin-top: 25px;
                        color: #333333;
                    }
                    .goBack{
                        text-align: center;
                        color: #4a82f4;
                        cursor: pointer;
                    }
                    .getCode{
                        height: 40px !important;
                        display: flex;
                        position: relative;
                        .get-code{
                            color: #4A82F4;
                            cursor: pointer;
                            flex: 1;
                            position: absolute;
                            right: 10px;
                        }
                        ::v-deep .el-input {
                            width: 200px;
                        }
                    }
                    .pass-inp{
                        height: 60px;
                        width: 334px;
                        border-radius: 4px;
                        border: 1px solid #EFEFEF;
                        border-radius: 4px 4px 4px 4px;
                        overflow: hidden;
                        display: flex;
                        align-items: center;
                        .pass-wd{
                            flex:1;
                        }
                        img{
                            width: 24px;
                            height: 24px;
                            margin-right: 15px;
                        }
                        p{
                            font-size: 14px;
                            color: #666666;
                            margin-top: 6px;     
                            height: 20px;
                            text-indent: 1em;
                            line-height: 20px;
                        }
                            ::v-deep .el-input__inner{
                                border: none;
                                height: 32px;
                            }
                            ::v-deep .el-input--suffix{
                                height: 32px;
                            }
                            ::v-deep .el-input__clear {
                                font-size: 20px;
                            }
                    }
                    .confirmBtn{
                        width: 100%;
                        text-align: center;
                        background-color: #4a82f4;
                        color: #fff;
                        margin-top: 20px;
                        cursor: pointer;
                    }
                }

                
                .number-content-box {
                    padding: 0 32px;
                    margin-top: 72px;
                    .number-content {
                        margin-top: 10px;
                        display: flex;
                        align-items: center;
                        padding-bottom: 10px;
                        .input-content {
                            width: 100%;
                            flex: 1;
                            ::v-deep .el-input-group__prepend{
                                color: #000;
                            }
                        }
                    }
                }
            }
        }
        .copyright {
            position: absolute;
            width: 100%;
            bottom: 30px;

            .text {
                text-align: center;
            }

            .copy {
                text-align: center;
            }
        }
        .maskGroup{
            position: absolute;
            height: 54px;
            right: 5px;
            bottom: 5px;
            img{
                height: 54px;
                width: 54px;
            }
        }
        .noRegister{
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 60px 0 70px;
            .no-register-text{
                font-size: 20px;
                font-family: PingFang SC-Medium, PingFang SC;
                font-weight: 500;
                color: #333333;
                margin-bottom: 46px;
            }
            .registered{
                background-color: #4a82f4;
                border-color: #4a82f4;
            }
        }
    }
}
::v-deep .el-input-group__prepend{
    background-color: #ffff;
}
::v-deep .el-form-item__error{
    // line-height: 19px;
    // height: 30px;
    position: relative;
    padding-top: 6px;
     width: 334px;
}
::v-deep .el-form-item {
    margin-bottom: 16px;
}
</style>